import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGifts, faAddressCard, faWallet, faSquare } from '@fortawesome/free-solid-svg-icons'
import * as HomepageRoundCardsStyle from './css/homepageRoundCards.module.css'
const HomepageRoundCards = () => (
  <>
    <div className={`container-fluid home themeColorTransparent ${HomepageRoundCardsStyle.home}`}>
      <div className='container '>
        <div className='row'>

          <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-2 '>
            <div className={`card text-center border-0 ${HomepageRoundCardsStyle.card}`}>
              <div className='card-body'>
                <div className='card-title'>
                  <span className='fa-stack fa-2x'>
                    <FontAwesomeIcon icon={faSquare} className='fa-stack-2x' color='white' />
                    <FontAwesomeIcon icon={faAddressCard} className='fa-stack-1x ' color='rgb(220,53,69)' />
                  </span>
                </div>
                <h4 className={`card-title ${HomepageRoundCardsStyle.cardTitle}`}>Din väg till körkortet</h4>
                <p className='card-text'>Här hittar du information om alla stegen mot ditt körkort.</p>
              </div>
              <div className='card-body'>
                <Link to='/information/din-vag-till-korkortet' className='btn btn-light ' role='button'>Information om körkort</Link>
              </div>
            </div>
          </div>

          <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-2 '>
            <div className={`card text-center border-0 ${HomepageRoundCardsStyle.card}`}>
              <div className='card-body'>
                <div className='card-title'>
                  <span className='fa-stack fa-2x'>
                    <FontAwesomeIcon icon={faSquare} className='fa-stack-2x' color='white' />
                    <FontAwesomeIcon icon={faWallet} className='fa-stack-1x ' color='rgb(220,53,69)' />
                  </span>
                </div>
                <h4 className={`card-title ${HomepageRoundCardsStyle.cardTitle}`}>Paketpriser</h4>
                <p className='card-text'>Vi erbjuder förmånliga paketpriser på våra körkortsutbildningar.</p>
              </div>
              <div className='card-body'>
                <Link to='/e-handel/Paketpriser' className='btn btn-light ' role='button'>Paketpriser </Link>
              </div>
            </div>
          </div>

          <div className='col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mt-2'>
            <div className={`card text-center border-0 ${HomepageRoundCardsStyle.card}`}>
              <div className='card-body'>
                <div className='card-title'>
                  <span className='fa-stack fa-2x'>
                    <FontAwesomeIcon icon={faSquare} className='fa-stack-2x' color='white' />
                    <FontAwesomeIcon icon={faGifts} className='fa-stack-1x ' color='rgb(220,53,69)' />
                  </span>
                </div>
                <h4 className={`card-title ${HomepageRoundCardsStyle.cardTitle}`}>Erbjudanden</h4>
                <p className='card-text'>Här hittar du information om <br />alla våra erbjudanden</p>
              </div>
              <div className='card-body'>
                <Link to='/e-handel/Erbjudanden' className='btn btn-light ' role='button'>Till alla våra erbjudanden</Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </>

)
export default HomepageRoundCards
