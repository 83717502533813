import React from 'react'
import * as HomepageCarStyle from './css/homepageCar.module.css'
import STRFooterLogga from '../images/ovrigt/car.png'
const HomepageCar = () => (

  <div className={`container-fluid home themeColorTransparent ${HomepageCarStyle.home}`}>
    <div className='container'>
      <div className='row'>
        <div className='col-md-6 pb-1 text-center offset-xl-3 offset-lg-3 offset-md-3'>
          <img className='STRFooterLogga' src={STRFooterLogga} alt='STR' />
        </div>
      </div>
    </div>
  </div>
)
export default HomepageCar
