import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import HomepageBackground from '../components/homepageBackground'
import HomepageEducations from '../components/homepageEducations'
import HomepageRoundCards from '../components/homepageRoundCards'
import HomepageCar from '../components/homepageCar'
import HomepageNumbers from '../components/homepageNumbers'
import Socialmedia from '../components/socialmedia'
const IndexPage = () => (
  <Layout>
    <Seo title='Home' />
    <HomepageBackground />
    <HomepageEducations />
    <HomepageRoundCards />
    <HomepageCar />
    <HomepageNumbers />
    <Socialmedia />

  </Layout>
)

export default IndexPage
