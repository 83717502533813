
import React from 'react'
import * as HompageSocialmedia from './css/hompageSocialmedia.module.css'
import Helmet from 'react-helmet'
const Socialmedia = () => (
  <>
    <div className={`container-fluid home themeColorTransparent ${HompageSocialmedia.home}`}>
      <div className='container'>

        <div className='row '>
          <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
            <h4 className={`${HompageSocialmedia.h4}`}>Följ oss gärna på våra sociala medier</h4>
            {/* <img src={require('../images/ovrigt/instagram.png')} alt='Swish' className='img-fluid rounded' /> */}
          </div>

          <div className='col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2 offset-xl-3  offset-lg-3'>
            <iframe title='instagram' src='https://snapwidget.com/embed/909182' className='snapwidget-widget' allowtransparency='true' frameBorder='0' scrolling='no' style={{ border: 'none', overflow: 'hidden', width: '100%', hight: '200px' }} />
            
            <Helmet>
              <script src='https://snapwidget.com/js/snapwidget.js' />
            </Helmet>
          </div>
        </div>
      </div>
    </div>
  </>

)
export default Socialmedia
