import React from 'react'
import CountUp from 'react-countup'
import VisibilitySensor from 'react-visibility-sensor'
import * as HomepageNumbersStyle from './css/homepageNumbers.module.css'
const HomepageNumbers = () => (
  <div className={`container-fluid home themeColorTransparent ${HomepageNumbersStyle.home}`}>

    <div className='container'>
      <h3 className={`text-center text-white pt-2 font-weight-normal ${HomepageNumbersStyle.h3}`}>NÅGRA SIFFROR</h3>
      <div className='row'>

        <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4'>
          <div className='card border-0'>
            <div className={`card-body ${HomepageNumbersStyle.cardBody}`}>
              <br />
              <CountUp end={9} duration={5} redraw>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <h1 className={`card-title text-center ${HomepageNumbersStyle.h1}`} ref={countUpRef}> </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <h5 className='card-subtitle mb-2 text-white text-center font-weight-normal'>ANTAL TRAFIKLÄRARE</h5>

            </div>
          </div>

        </div>
        <div className='col-sm-12 col-md-12 col-lg-4 col-md-4'>
          <div className='card border-0'>
            <div className={`card-body ${HomepageNumbersStyle.cardBody}`}>
              <br />
              <CountUp end={13} duration={5} redraw>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <h1 className={`card-title text-center ${HomepageNumbersStyle.h1}`} ref={countUpRef}> </h1>
                  </VisibilitySensor>
                )}
              </CountUp>
              <h5 className='card-subtitle mb-2 text-white text-center font-weight-normal'>ANTAL BILAR</h5>
            </div>
          </div>
        </div>
        <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4 '>
          <div className='card border-0'>
            <div className={`card-body ${HomepageNumbersStyle.cardBody}`}>
              <h5 className='card-subtitle mb-2 text-white text-center font-weight-normal'>ÖVER</h5>
              <CountUp end={20000} duration={2} redraw>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <h1 className={`card-title text-center  ${HomepageNumbersStyle.h1}`} ref={countUpRef}> </h1>
                  </VisibilitySensor>
                )}
              </CountUp>

              <h5 className='card-subtitle mb-2 text-white text-center font-weight-normal'>UTFÄRDADE KÖRKORT</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

)
export default HomepageNumbers
