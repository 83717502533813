import React from 'react'
import { Link } from 'gatsby'
import * as HomepageEducationsStyle from './css/homepageEducations.module.css'

import Personbilsutbildning from '../images/utbildningar/personbilsutbildning.jpg'
import Handledarutbildning from '../images/utbildningar/handledarutbildning.jpg'
import Riskettan from '../images/utbildningar/riskutbildning1.jpg'
import Risktvåan from '../images/utbildningar/riskutbildning2.jpg'
import Intensivutbildning from '../images/utbildningar/intensivutbildning.jpg'
import Teoriundervisning from '../images/utbildningar/teoriundervisning.jpg'

const HomepageEducations = () => (
  <>

    <div className={`container-fluid home ${HomepageEducationsStyle.home}`}>
      <div className='container'>
        <div className='row'>

          <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-5 pb-2'>
            <div className={`card ${HomepageEducationsStyle.card}`}>
              <img className={`card-img-top ${HomepageEducationsStyle.cardImgTop}`} src={Personbilsutbildning} alt='Personbilsutbildning' />
              <h1 className={`card-title font-weight-normal ${HomepageEducationsStyle.cardTitle}`}>Personbilsutbildning</h1>
              <div className={`card-body text-center ${HomepageEducationsStyle.cardBody}`}>
                <Link to='/utbildningar/personbilsutbildning' className='btn btn-danger'>Läs mer </Link>
              </div>
            </div>
          </div>

          <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-5 pb-2'>
            <div className={`card ${HomepageEducationsStyle.card}`}>
              <img className={`card-img-top ${HomepageEducationsStyle.cardImgTop}`} src={Handledarutbildning} alt='Handledarutbildning' />
              <h1 className={`card-title font-weight-normal ${HomepageEducationsStyle.cardTitle}`}>Handledarutbildning</h1>
              <div className={`card-body text-center ${HomepageEducationsStyle.cardBody}`}>
                {/* <Link to='/e-handel/productDetails/5470' className='btn btn-danger'>Boka här </Link> */}
                <Link to='/boka-utbildning' className='btn btn-danger'>Boka här </Link>
              </div>
            </div>
          </div>

          <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-5 pb-2'>
            <div className={`card ${HomepageEducationsStyle.card}`}>
              <img className={`card-img-top ${HomepageEducationsStyle.cardImgTop}`} src={Riskettan} alt='Riskettan' />
              <h1 className={`card-title font-weight-normal ${HomepageEducationsStyle.cardTitle}`}>Riskettan</h1>
              <div className={`card-body text-center ${HomepageEducationsStyle.cardBody}`}>
                {/* <Link to='/e-handel/productDetails/5473' className='btn btn-danger'>Boka här </Link> */}
                <Link to='/boka-utbildning' className='btn btn-danger'>Boka här </Link>
              </div>
            </div>
          </div>

          <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-5 pb-2'>
            <div className={`card ${HomepageEducationsStyle.card}`}>
              <img className={`card-img-top ${HomepageEducationsStyle.cardImgTop}`} src={Risktvåan} alt='Risktvåan' />
              <h1 className={`card-title font-weight-normal ${HomepageEducationsStyle.cardTitle}`}>Risktvåan</h1>
              <div className={`card-body text-center ${HomepageEducationsStyle.cardBody}`}>
                {/* <Link to='/e-handel/productDetails/5474' className='btn btn-danger'>Boka här </Link> */}
                <Link to='/utbildningar/riskutbildningar' className='btn btn-danger'>Läs mer </Link>
              </div>
            </div>
          </div>

          <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-5 pb-2'>
            <div className={`card ${HomepageEducationsStyle.card}`}>
              <img className={`card-img-top ${HomepageEducationsStyle.cardImgTop}`} src={Intensivutbildning} alt='Intensivutbildning' />
              <h1 className={`card-title font-weight-normal ${HomepageEducationsStyle.cardTitle}`}>Intensivutbildning</h1>
              <div className={`card-body text-center ${HomepageEducationsStyle.cardBody}`}>
                <Link to='/utbildningar/intensivutbildning' className='btn btn-danger'>Läs mer </Link>
              </div>
            </div>
          </div>

          <div className='col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-5 pb-2 mb-5'>
            <div className={`card ${HomepageEducationsStyle.card}`}>
              <img className={`card-img-top ${HomepageEducationsStyle.cardImgTop}`} src={Teoriundervisning} alt='Teoriundervisning' />
              <h1 className={`card-title font-weight-normal ${HomepageEducationsStyle.cardTitle}`}>Teoriundervisning</h1>
              <div className={`card-body text-center ${HomepageEducationsStyle.cardBody}`}>
                <Link to='/utbildningar/teoriundervisning' className='btn btn-danger'>Läs mer </Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </>

)
export default HomepageEducations
